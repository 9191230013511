import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import flatpickr from "flatpickr";
import axios from "axios";

const DATE_TIME_FORMAT = "D n/d h:i K";

export default class extends Controller {
  static targets = [];

  connect() {
    const calendar = new Calendar(this.element, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],

      header: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },

      aspectRatio: 1.55,

      navLinks: true,

      defaultView: "timeGridWeek",

      minTime: "07:00:00",
      maxTime: "20:00:00",

      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5], // 0 = Sunday
        startTime: "09:00",
        endTime: "17:00",
      },

      events: "/job_appointments.json",

      editable: true,
      eventDrop: this.updateAppointmentFromCalendarEvent,
      eventResize: this.updateAppointmentFromCalendarEvent,

      // FullCalendar will navigate to event.url automatically,
      // but we want it to do so in a new tab
      eventClick: function (info) {
        info.jsEvent.preventDefault();

        if (info.event.url) {
          window.open(info.event.url);
        }
      },

      selectable: true,
      select: (selectionInfo) => {
        const newAppointmentModal = document.querySelector("#new-appointment-modal");

        // set the inputs based on selection
        const windowStartInput = newAppointmentModal.querySelector("#appointment_form_scheduled_window_start");
        const windowEndInput = newAppointmentModal.querySelector("#appointment_form_scheduled_window_end");
        const estimatedStartInput = newAppointmentModal.querySelector("#appointment_form_estimated_start_time");
        flatpickr(windowStartInput, {
          enableTime: true,
          defaultDate: selectionInfo.start,
          dateFormat: DATE_TIME_FORMAT,
        });
        flatpickr(windowEndInput, { enableTime: true, defaultDate: selectionInfo.end, dateFormat: DATE_TIME_FORMAT });
        flatpickr(estimatedStartInput, {
          enableTime: true,
          defaultDate: selectionInfo.start,
          dateFormat: DATE_TIME_FORMAT,
        });

        // open the new appointment modal
        const modalController = this.application.getControllerForElementAndIdentifier(newAppointmentModal, "modal");
        const fakeEvent = {
          preventDefault: () => {},
          target: this.element,
        };
        modalController.open(fakeEvent);
      },
    });

    calendar.render();
  }

  updateAppointmentFromCalendarEvent(info) {
    const updateParams = {
      job_appointment_id: info.event.id,
      scheduled_window_start: info.event.start,
      scheduled_window_end: info.event.end,
      estimated_start_time: info.event.start,
    };

    const token = document.getElementsByName("csrf-token")[0].content;
    const config = {
      headers: { "X-CSRF-Token": token },
    };

    if (confirm("Are you sure you want to save this change?")) {
      axios
        .patch(`/appointments/${info.event.groupId}.json`, updateParams, config)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          info.revert();
        });
    } else {
      info.revert();
    }
  }
}
