import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.flatpickr = flatpickr(this.inputTarget, {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "m/d/Y",
    });
  }

  clear() {
    this.flatpickr.clear();
  }
}
