import { Controller } from "stimulus";

function getDefaultWindowEnd(windowStart) {
  const startInMs = windowStart.getTime();
  const defaultDuration = 2 * 60 * 60 * 1000;
  const endInMs = startInMs + defaultDuration;
  return new Date(endInMs);
}

export default class extends Controller {
  static targets = ["windowStartInput", "windowEndInput", "estimatedStartInput"];

  connect() {
    window.setTimeout(() => {
      this.windowStartPicker = this.windowStartInputTarget._flatpickr;
      this.windowEndPicker = this.windowEndInputTarget._flatpickr;
      this.estimatedStartPicker = this.estimatedStartInputTarget._flatpickr;

      this.windowStartPicker.config.onChange.push(this.updateTimes.bind(this));
    });
  }

  updateTimes() {
    const newWindowStart = this.windowStartPicker.selectedDates[0];
    const newWindowEnd = getDefaultWindowEnd(newWindowStart);

    this.windowEndPicker.setDate(newWindowEnd, true);
    this.estimatedStartPicker.setDate(newWindowStart, true);
  }
}
