import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "destination" ]

    updateSelection(event) {
        let sourceValue = event.target.checked
        this.destinationTargets.forEach((e) => {
            e.checked = sourceValue
        })
    }
}
