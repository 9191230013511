import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "links", "template" ]

    connect() {
        this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
        this.insertionTargetElement = this.element.querySelector(this.data.get("insertionTarget"))
        this.insertionPosition = this.data.get("insertionPosition")
    }

    addAssociation(event) {
        event.preventDefault()

        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.insertionTargetElement.insertAdjacentHTML(this.insertionPosition, content)
    }

    removeAssociation(event) {
        event.preventDefault()

        const wrapper = event.target.closest("." + this.wrapperClass)

        // New records are simply removed from the page
        if (wrapper.dataset.newRecord === "true") {
            wrapper.remove()

        // Existing records are hidden and flagged for deletion
        } else {
            wrapper.querySelector("input[name*='_destroy']").value = 1
            wrapper.style.display = 'none'
        }
    }
}
