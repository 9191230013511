import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import AwsS3 from "@uppy/aws-s3";

function randomstring() {
  return Math.random().toString(36).substring(2, 15);
}

/**
 * Replace a multiple file input with an Uppy uploader
 */
export default class extends Controller {
  static targets = ["input"];
  static values = { accept: String, uploadServer: String, inputName: String };

  connect() {
    // hide input and remove its name so it isn't submitted
    this.inputTarget.type = "hidden";
    this.inputTarget.name = "";

    // attach uppy
    const uppyDiv = document.createElement("div");
    this.element.insertBefore(uppyDiv, this.inputTarget);
    const uppy = this._buildUploader();
    uppy
      .use(Webcam, {
        modes: ["picture"],
        showVideoSourceDropdown: true,
        preferredImageMimeType: "image/png",
        videoConstraints: {
          facingMode: "environment",
          width: { min: 720, ideal: 3840, max: 3840 },
        },
      })
      .use(Dashboard, {
        target: uppyDiv,
        inline: true,
        height: 600,
        replaceTargetContent: true,
        plugins: ["Webcam"],
      });

    // add hidden input for each successful file uploaded
    uppy.on("upload-success", (file, response) => {
      console.log("upload-success triggered:", file, response);
      const newInput = document.createElement("input");
      newInput.type = "hidden";
      newInput.name = this._generateInputName();
      newInput.value = this._uploadedFileData(file, response);
      this.element.appendChild(newInput);
    });
  }

  _generateInputName() {
    return this.inputNameValue.replace("[]", `[${randomstring()}]`);
  }

  _buildUploader() {
    const uppy = Uppy({
      id: this.inputTarget.id,
      autoProceed: true,
      restrictions: {
        allowedFileTypes: this.acceptValue.split(","),
      },
    });

    if (this.uploadServerValue == "s3") {
      uppy.use(AwsS3, {
        companionUrl: "/", // will call Shrine's presign endpoint mounted on `/s3/params`
      });
    } else {
      uppy.use(XHRUpload, {
        endpoint: "/upload", // Shrine's upload endpoint
      });
    }

    return uppy;
  }

  _uploadedFileData = (file, response) => {
    if (this.uploadServerValue == "s3") {
      const id = file.meta["key"].match(/^cache\/(.+)/)[1]; // object key without prefix
      return JSON.stringify(this._fileData(file, id));
    } else {
      return JSON.stringify(response.body);
    }
  };

  _fileData = (file, id) => ({
    id: id,
    storage: "cache",
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  });
}
