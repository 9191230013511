import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "toggle"];

  connect() {}

  toggleMenu(event) {
    event.preventDefault();
    this.menuTarget.classList.toggle("toggled");
    this.toggleTarget.classList.toggle("toggled");
  }

  showMenu() {
    this.menuTarget.classList.add("toggled");
    this.toggleTarget.classList.add("toggled");
  }

  hideMenu() {
    this.menuTarget.classList.remove("toggled");
    this.toggleTarget.classList.remove("toggled");
  }
}
