import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dateInput", "link"];

  connect() {
    this.dateInputTarget.addEventListener("input", (e) => this.updateLinks(e));
  }

  updateLinks(e) {
    const newDate = e.target.value;
    this.linkTargets.forEach((link) => {
      link.href = link.href.replace(/\d{4}-\d{2}-\d{2}/i, newDate);
    });
  }
}
