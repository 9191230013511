import { Controller } from "stimulus"
// import { moment } from "moment"

export default class extends Controller {
    static targets = [ "start", "end" ]

    connect() {
        // console.log("Connected time-range controller")
    }

    updateEndTime(event) {
        // console.log('triggered updateEndTime()')
        let newStartMoment = moment(this.startTarget.value)
        let newEndMoment = newStartMoment.add(2, 'hours')
        this.endTarget.value = newEndMoment.format('YYYY-MM-DD hh:mm A')
    }
}
