/**
 * Thin wrapper around toastr.js
 * https://github.com/CodeSeven/toastr
 */
import { Controller } from "stimulus";
import toastr from "toastr";

export default class extends Controller {
  connect() {
    toastr.options.positionClass = this.toastPositionClass;
    toastr[this.toastType](this.message);
  }

  get toastPositionClass() {
    if (window.innerWidth > 768) {
      return "toast-top-right";
    } else {
      return "toast-bottom-full-width";
    }
  }

  get toastType() {
    return {
      notice: "info",
      alert: "error",
    }[this.data.get("context")];
  }

  get message() {
    return this.element.textContent;
  }
}
