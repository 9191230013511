import { Controller } from "stimulus";
import TomSelect from "tom-select";
import axios from "axios";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [];

  static values = {
    postUrl: String,
    formModalId: String,
  };

  static classes = ["modalShowing", "modalHidden"];

  connect() {
    this.selectizeCallback = null;

    this.tomSelect = new TomSelect(this.element, {
      openOnFocus: false,
      create: (input, callback) => {
        this.selectizeCallback = callback;
        this.openModal(input);
      },
    });

    this._setupModal();
  }

  openModal(input) {
    this._lockScroll();
    if (this.hasModalShowingClass) this.modalContainer.classList.add(this.modalShowingClass);
    if (this.hasModalHiddenClass) this.modalContainer.classList.remove(this.modalHiddenClass);
    document.body.insertAdjacentHTML("beforeend", this.backgroundHtml);
    this.background = document.querySelector(`#${this.backgroundId}`);
    this._populateModalFormFromInput(input);
  }

  closeModal() {
    this._unlockScroll();
    if (this.hasModalShowingClass) this.modalContainer.classList.remove(this.modalShowingClass);
    if (this.hasModalHiddenClass) this.modalContainer.classList.add(this.modalHiddenClass);
    if (this.background) {
      this.background.remove();
    }
  }

  _setupModal() {
    this.modalContainer = document.querySelector(`#${this.formModalIdValue}`);
    this.allowBackgroundClose = false;

    this._setupModalEvents();
    this._setupModalBackground();
    this._setupFormEvents();
  }

  _setupModalEvents() {
    // close modal on background click
    this.modalContainer.addEventListener("click", (e) => {
      if (this.allowBackgroundClose && e.target === this.modalContainer) {
        this.selectizeCallback();
        this.closeModal();
      }
    });

    // close modal on ESC key
    window.addEventListener("keyup", (e) => {
      if (e.code === 27 && !this.modalContainer.classList.contains(this.toggleClass)) {
        this.selectizeCallback();
        this.closeModal();
      }
    });

    // close modal on clicking any .close-modal controls
    this.modalContainer.querySelectorAll(".close-modal").forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        this.selectizeCallback();
        this.closeModal();
      });
    });
  }

  _setupModalBackground() {
    this.backgroundHtml =
      '<div id="modal-background" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>';
    this.backgroundId = "modal-background";
  }

  _setupFormEvents() {
    this.modalForm = this.modalContainer.querySelector("form");
    this.modalFormSubmitButton = this.modalForm.querySelector("[type=submit]");

    this.modalForm.addEventListener("submit", (e) => {
      e.preventDefault();

      axios
        .post(this.postUrlValue, new FormData(this.modalForm))
        .then((response) => {
          const customer = response.data.customer;
          const { id, last_name, first_name } = customer;

          let label = last_name;
          if (first_name && first_name.length > 0) {
            label += `, ${first_name}`;
          }

          this.selectizeCallback({
            value: id,
            text: label,
          });
          this.closeModal();
          this.modalForm.reset();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              const { errors } = error.response.data;
              this._showErrors(errors);
            } else {
              console.log(error.response);
              this._showErrors(["Oops! There was an error processing your request. Please try again."]);
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            this._showErrors(["Oops! There was an error processing your request. Please try again."]);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            this._showErrors(["Oops! There was an error processing your request. Please try again."]);
          }

          // for some reason we must delay a bit, otherwise it seems to run before the disable call...?
          setTimeout(() => Rails.enableElement(this.modalFormSubmitButton), 100);
        });
    });
  }

  _lockScroll() {
    // Add right padding to the body so the page doesn't shift when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    this._saveScrollPosition();

    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden");

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  _unlockScroll() {
    document.body.style.paddingRight = null;
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden");
    this._restoreScrollPosition();
    document.body.style.top = null;
  }

  _saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  _restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }

  _populateModalFormFromInput(input) {
    if (!input || !input.length > 0) return;

    const firstNameInput = this.modalContainer.querySelector("#customer_first_name");
    const lastNameInput = this.modalContainer.querySelector("#customer_last_name");

    const words = input.split(/\s/, 2);
    if (words.length > 1) {
      firstNameInput.value = words[0];
      lastNameInput.value = words[1];
    } else {
      lastNameInput.value = input;
    }

    firstNameInput.focus();
  }

  _showErrors(errors) {
    const errorsContainer = this.modalContainer.querySelector(".errors");
    errorsContainer.classList.remove("hidden");

    // clear any existing errors
    errorsContainer.innerHTML = "";

    // render new errors
    const errorClasses = "py-2 px-2 border-l-2 border-red-500 bg-red-100 text-red-600";
    errors.forEach((message) => {
      errorsContainer.insertAdjacentHTML(
        "beforeend",
        `<div class="${errorClasses}"><i class="fas fa-exclamation-triangle mr-2"></i>${message}</div>`,
      );
    });
  }
}
