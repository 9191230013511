import { Controller } from "stimulus";
import TomSelect from "tom-select";
import axios from "axios";

export default class extends Controller {
  static targets = [];

  connect() {
    this.selectizeCallback = null;

    this.tomSelect = new TomSelect(this.element, {
      create: (input, callback) => {
        console.log("location-select create callback initiated");
        this.selectizeCallback = callback;
        this.openModal();
      },
    });

    this.toggleClass = "hidden";
    this.allowBackgroundClose = false;

    this._setupModalEvents();
    this._setupModalBackground();
    this._setupFormEvents();

    console.log("Connected location-select controller");
  }

  openModal() {
    this._lockScroll();
    this.modalContainer.classList.remove(this.toggleClass);
    document.body.insertAdjacentHTML("beforeend", this.backgroundHtml);
    this.background = document.querySelector(`#${this.backgroundId}`);
  }

  closeModal() {
    this._unlockScroll();
    this.modalContainer.classList.add(this.toggleClass);
    if (this.background) {
      this.background.remove();
    }
  }

  _setupModalEvents() {
    this.modalContainer = document.getElementById(this.data.get("formModalId"));

    this.modalContainer.addEventListener("click", (e) => {
      if (this.allowBackgroundClose && e.target === this.modalContainer) {
        this.selectizeCallback();
        this.closeModal();
      }
    });

    window.addEventListener("keyup", (e) => {
      if (e.keyCode === 27 && !this.modalContainer.classList.contains(this.toggleClass)) {
        this.selectizeCallback();
        this.closeModal();
      }
    });

    this.modalContainer.querySelectorAll(".close-modal").forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        this.selectizeCallback();
        this.closeModal();
      });
    });
  }

  _setupModalBackground() {
    this.backgroundHtml =
      '<div id="modal-background" class="fixed top-0 left-0 w-full h-full" style="background-color: rgba(0, 0, 0, 0.8); z-index: 9998;"></div>';
    this.backgroundId = "modal-background";
  }

  _setupFormEvents() {
    this.modalForm = this.modalContainer.querySelector("form");
    this.modalForm.addEventListener("submit", (e) => {
      e.preventDefault();

      axios
        .post("/locations.json", new FormData(this.modalForm))
        .then((response) => {
          const location = response.data.location;
          this.selectizeCallback({
            value: location.id,
            text: location.name || location.street,
          });
          this.closeModal();
          this.modalForm.reset();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  _lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this._saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden");

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  _unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden");

    // Restore the scroll position of the body before it got locked
    this._restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  _saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  _restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
